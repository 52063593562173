import { Helmet } from 'react-helmet'

import * as React from 'react'
import Layout from '../components/Layout'

import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import FAQs from '../components/FAQs'

import { Box, Flex } from 'grid-styled'
import {
  HomeHeader,
  FormContainer,
  AlternatingContent,
  AboutContact,
  VideoContainer,
  info,
} from '.'
import Form from '../components/Form'
import {
  Text,
  GallerySection,
  Content,
  MainContent,
} from '../components/styles'
import Button from '../components/Button'
import ContentCard from '../components/ContentCard'
import Hero from '../components/Hero'
import { Video } from '../components/procedures'
import GatsbyImage from 'gatsby-image'
import CTA from '../components/CTA'

class Comp extends React.Component {
  state = {
    active: 'juniorDoctors',
  }
  makeVisible = (id: string) => {
    const section = document.getElementById(id)
    const others = document.getElementsByClassName('article-stages')
    if (section !== null) {
      Array.from(others).map((el) => (el.style.display = 'none'))
      section.style.display = 'block'
      this.setState({ active: id })
    }
  }
  render() {
    return (
      <Layout header="light">
        <Helmet>
          <title>
            Medical Care Professionals - Parinity Financial Professionals
          </title>
          <meta
            name="description"
            content="At Parinity, we understand the pressures medical professionals are
            faced. We've custom designed our medical financial strategies from the ground up for every stage of your career."
          />
        </Helmet>

        <HomeHeader slant={false} height="75vh">
          <figure>
            <Img fluid={this.props.data.jpBackground.childImageSharp.fluid} />
          </figure>
          <div>
            <h2>medical professionals</h2>
            <Text size="large">
              We are a boutique planning firm and only partner with a select
              number of medical professionals each year to deliver tailored
              financial advice for those working in the health sector.
            </Text>
            <Link to="/contact">
              <Button outline={true} color="#fff">
                Contact us
              </Button>
            </Link>
          </div>
          <FormContainer>
            <div>
              <Form title="Contact us today" showMessage={true} />
            </div>
          </FormContainer>
        </HomeHeader>

        <Content style={{ paddingTop: 100 }}>
          <h1>parinity - medical financial specialists</h1>

          <span>
            At Parinity, we understand the pressures medical professionals are
            faced with particularly when it comes to managing their finances.
          </span>
          <h4>advice at every level</h4>

          <p>
            Long shifts, continuing professional development, family
            obligations, trying to fit in some downtime – it's hard to focus on
            your own financial future when you are busy focussing on the future
            health of your patients and trying to live your life!
          </p>

          <p>
            That’s where we come in. We understand the specific financial
            challenges medical professionals face and have developed a raft of
            solutions for these. Whether you are an intern starting your career
            or a specialist needing tax planning and asset protection
            structuring – we can help.
          </p>

          <h4>we're here to help</h4>

          <p>
            We are a boutique planning firm and only partner with a select
            number of medical professionals each year to deliver our Parinity
            Partnership Program, a financial planning partnership designed
            specifically for medical professionals to take advantage of the
            financial benefits that come with working in the health sector.
          </p>

          <p>
            With so many things we can help you with, why not book an
            appointment now so we can help you take control of your financial
            future.
          </p>

          <Link to="/contact/">
            <Button inverted={true}>Contact us today!</Button>
          </Link>
        </Content>

        <Hero
          bgImage={this.props.data.blurredHero.childImageSharp}
          height="550px"
        >
          <h3>Want to find out more?</h3>
          <p>
            Call 07 3114 2500 or complete the enquiry form to contact us today
            and find out how we can help.
          </p>
          <Flex>
            <Button
              onClick={(e) => {
                e.preventDefault()
                Calendly.showPopupWidget('https://calendly.com/lancecheung/')
              }}
              inverted={true}
            >
              Book an appointment now!
            </Button>
          </Flex>
        </Hero>

        <MainContent>
          <Flex justifyContent="center">
            <h2>advice for every stage</h2>
          </Flex>
          <section>
            <aside>
              <div>
                <strong>your career</strong>
                <span
                  onClick={() => {
                    this.makeVisible('juniorDoctors')
                  }}
                  className={
                    this.state.active === 'juniorDoctors' ? 'active' : ''
                  }
                >
                  1. Junior Doctors & Interns
                </span>
                <span
                  onClick={() => {
                    this.makeVisible('registrars')
                  }}
                  className={this.state.active === 'registrars' ? 'active' : ''}
                >
                  2. Registrars & SMOs
                </span>
                <span
                  onClick={() => {
                    this.makeVisible('specialists')
                  }}
                  className={
                    this.state.active === 'specialists' ? 'active' : ''
                  }
                >
                  3. Specialists & Private Practice
                </span>
              </div>
            </aside>
            <article
              className="article-stages"
              id="juniorDoctors"
              style={{ display: 'block' }}
            >
              <h3>Interns and Junior Doctors</h3>
              <p>
                Firstly, good work on getting through the study and requirements
                to get here! Now it’s time to apply what you have learnt and get
                your career underway. We often find this is an extremely busy
                time for new doctors as you get thrust into managing shift work,
                new rotations and the responsibilities of seeing patients for
                real! And just as you are getting your head around the
                transition, you are swamped by payroll to setup salary
                packaging, nominate your superfund, choose your investments,
                what about life insurance…!?
              </p>
              <p>
                It all gets a bit much. That’s why we offer our Free Medico
                Package meeting (normally $330 Incl GST). Our solution is to
                help you get the basics setup quickly and efficiently so you can
                just focus on getting through this first year until you find
                your groove. All you need to do is book in a meeting with us and
                within an hour or so, we can have all your salary packaging,
                super, tax and any other questions you might have answered...and
                yes, it did say this meeting is free.
              </p>
              <GatsbyImage
                fluid={this.props.data.junior.childImageSharp.fluid}
              />
              <h4>Things we often help with at this stage:</h4>
              <ul>
                <li>Salary Packaging Setup</li>
                <li>Superannuation Review/Setup</li>
                <li>Tax Advice and Returns</li>
                <li>Cashflow Management including Bank Account Structuring</li>
                <li>Debt elimination plans</li>
                <li>
                  Purchasing your first property (including property research,
                  contract negotiations, finance and structuring)
                </li>
                <li>
                  Taking advantage of specific Life Insurance offers only
                  available to new Medical Professionals
                </li>
              </ul>
              <p
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <a href="https://www.uqpaeds.org/" target="_blank">
                  <img
                    className="img-sponsor"
                    src="https://static.wixstatic.com/media/5256d0_18c34540e0c1411089b926dd47ce6b09~mv2.png/v1/fill/w_580,h_562,al_c,q_85,usm_0.66_1.00_0.01/Screen%20Shot%202018-08-03%20at%208_54_17%20am.webp"
                  />
                </a>
                We're a proud sponsor of{' '}
                <a
                  href="https://www.uqpaeds.org/"
                  target="_blank"
                  style={{ marginLeft: 5, textDecoration: 'underline' }}
                >
                  UQPAEDS
                </a>
              </p>
              <Button
                onClick={(e) => {
                  e.preventDefault()
                  this.makeVisible('registrars')
                }}
              >
                Next Stage &mdash; Registrars & SMOs
              </Button>
            </article>

            <article className="article-stages" id="registrars">
              <h3>Registrars & SMOs</h3>
              <p>
                OK, so by now you know the lay of the land and you are working
                towards getting onto the training program you want or you are
                already registered on one. You are still busy managing your life
                around your shifts, but you are starting to have a clearer idea
                of what you want to achieve both personally and for your career.
                Great.
              </p>

              <h4>parinity's registrar plan</h4>
              <p>
                This is where we can really start to ramp up the development and
                implementation of your financial plan. There is a lot to be said
                for getting the foundations right in any plan and this is
                definitely true when it comes to your finances. If you haven’t
                already, now is the time to really bed down your cashflow and
                budgeting, clearing bad debts and getting ready to build assets,
                whether that be property or shares.
              </p>
              <p>
                You may also want to start considering how you will fund a
                fellowship year if that is part of the program you are on. We
                have helped plenty of client’s prepare for this, and it’s a lot
                easier the earlier you start. In fact, you’ll find everything to
                do with your finances will be a lot easier the earlier you
                start!
              </p>
              <GatsbyImage
                fluid={this.props.data.registrars.childImageSharp.fluid}
              />
              <h4>things we often help with at this stage:</h4>
              <ul>
                <li>
                  Upgrading your property or buying an investment property
                  (including property research, contract negotiations, finance
                  and structuring)
                </li>
                <li>Debt elimination plans</li>
                <li>Cashflow and budgeting efficiency</li>
                <li>Broader insurance coverage and risk mitigation</li>
                <li>
                  Life event planning (funding a wedding or maternity leave)
                </li>
                <li>Starting long term investment plans</li>
                <li>Tax effective planning</li>
              </ul>
              <Button
                onClick={(e) => {
                  e.preventDefault()
                  this.makeVisible('specialists')
                }}
              >
                Next Stage &mdash; Specialists & Private Practice
              </Button>
            </article>

            <article className="article-stages" id="specialists">
              <h3>Specialists & Private Practice</h3>
              <p>
                As a Specialist, you are now reaping the rewards of your years
                of hard work and determination. Depending on your specialty,
                you’ll probably have a bit more control over your time. Your
                financial position should be strong at this stage and if it
                isn’t, make sure you book in to meet with a Parinity Adviser
                sooner rather than later.
              </p>
              <p>
                With good cashflow comes greater financial complexity, with the
                biggest hurdle we find people face at this stage is succumbing
                to ‘Lifestyle Creep’. Yes, this is a thing, and it can be
                absolutely detrimental to your long term financial planning and
                can easily undo all your years of hard work and sacrifices that
                got you to this position in the first place.
              </p>

              <p>
                Our financial planning work with clients at this level really is
                around accountability and remaining committed to your financial
                goals. We will help keep you focussed on what is truly important
                in your life by reminding you of it when you get side tracked.
                For client’s who have been with us since their intern years,
                it’s at this point we are having serious conversations about
                what financial independence actually feels like for them.
              </p>
              <GatsbyImage
                fluid={this.props.data.specialists.childImageSharp.fluid}
              />
              <h4>things we often help with at this stage:</h4>
              <ul>
                <li>
                  Transition to Self-Employment/Private practice including tax
                  structuring
                </li>
                <li>Property portfolio expansion</li>
                <li>Providing for children’s education and future funds</li>
                <li>Home loan elimination</li>
                <li>Self Managed Super Strategies</li>
                <li>
                  Financial Independence and Early Retirement outcomes (Overseas
                  volunteering or career breaks)
                </li>
                <li>Detailed Estate Planning</li>
              </ul>
            </article>
          </section>
        </MainContent>
        <CTA
          title="Download our wealth tracking app"
          subtitle="A better tracking solution."
          button="Access Parinity Wealthdesk"
          inverted={true}
          to="https://parinity.wealthdesk.com.au/my/home"
        />

        <AboutContact />
      </Layout>
    )
  }
}
export default Comp

export const query = graphql`
  query GalleryQuery {
    jpBackground: file(relativePath: { eq: "medico-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    junior: file(relativePath: { eq: "junior.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    specialists: file(relativePath: { eq: "specialists.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    registrars: file(relativePath: { eq: "registrars.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whyUs: file(relativePath: { eq: "why-us-jp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    accessories: file(relativePath: { eq: "roofing-brisbane.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blurredHero: file(relativePath: { eq: "cta-bg.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gallery: allFile(filter: { relativeDirectory: { eq: "gallery/patios" } }) {
      edges {
        node {
          childImageSharp {
            fixed(width: 400, height: 400, toFormat: JPG) {
              ...GatsbyImageSharpFixed
            }
            modal: fluid(maxWidth: 1200, quality: 98, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
